



















import { Usuario } from "@/shared/dtos/usuario";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, PropSync, Vue } from "vue-property-decorator";

@Component({
  components: {
    DynamicTable: () => import("../dynamic_tables/dynamic_table.vue"),
  },
})
export default class usuariosTareas extends Vue {
  @PropSync("usuarios", { default: [] }) Xusuarios!: Usuario[];
  private show_dialog = false;

  async created() {
    await usuarioModule.getusuarios();
  }

  users_add(x: any) {
    var ids = this.Xusuarios.map((x) => x.id);
    return usuarioModule.usuarios.filter((x) => !ids.includes(x.id));
  }

  add_user_list(x: Usuario) {
    this.Xusuarios.push(x);
    this.show_dialog = false;
  }

  data_source(x: any) {
    return this.Xusuarios;
  }

  add_user() {
    this.show_dialog = true;
  }

  eliminar(usuario: Usuario) {
    var index = this.Xusuarios.findIndex((x) => x.id === usuario.id);
    this.Xusuarios = this.Xusuarios.splice(index, 1);
    //@ts-ignore
    this.$refs["data_table_users"].refresh();
  }
}
